import(/* webpackMode: "eager", webpackExports: ["Reviews"] */ "/vercel/path0/components/Reviews/Review.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/Animations/animation-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/Hero/HeroSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/Navbar/Navbar.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ui/Navbar/Navlinks.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
